// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/bg/hero-4.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hero-section{\n    background-image: linear-gradient( rgba(19, 19, 141, 0.5),  rgba(19, 19, 141, 0.5)),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    /* height: calc(100vh - 0.7rem); */\n}\n.hero-section>img{\n    width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/pages/home/home.css"],"names":[],"mappings":"AAAA;IACI,2HAAqH;IACrH,kCAAkC;AACtC;AACA;IACI,WAAW;AACf","sourcesContent":[".hero-section{\n    background-image: linear-gradient( rgba(19, 19, 141, 0.5),  rgba(19, 19, 141, 0.5)),url('../../assets/bg/hero-4.jpg');\n    /* height: calc(100vh - 0.7rem); */\n}\n.hero-section>img{\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
