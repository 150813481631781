export const speakers=[
    {
        img:require("./speakers/SiwAndersen.jpeg"),
        name:"Siw Andersen ",
        designation:"Ceo",
        company:"Oslo Business Region",
        link:"https://www.linkedin.com/in/siwander/"
    },    {
        img:require("./speakers/Henrik.jpeg"),
        name:"Henrik Botten Taubøll",
        designation:"Wiersholm",
        company:" Proptech Norway",
        link:"https://www.linkedin.com/in/tauboll/"
    },    {
        img:require("./speakers/Maurizio.jpeg"),
        name:"Maurizio Rossi",
        designation:"Founder",
        company:"H-Farm",
        link:"https://it.linkedin.com/in/rossimaurizio/"
    },    {
        img:require("./speakers/Rupa.jpeg"),
        name:"Rupa Dash",
        designation:"Chairman & CEO",
        company:"World Woman Foundation",
        link:"https://www.linkedin.com/in/dashrupa/"
    },    {
        img:require("./speakers/NIO.jfif"),
        name:"An Ho",
        designation:"Head of User Development and Operations",
        company:"NIO",
        link:"https://www.linkedin.com/in/anvinhho/"
    },    {
        img:require("./speakers/gianfranco.png"),
        name:"Gianfranco Pizzuto",
        designation:"CEO",
        company:"Automobili-estrema",
        link:"https://it.linkedin.com/in/giuseppesurace/"
    },    {
        img:require("./speakers/Giuseppe.jpg"),
        name:"Giuseppe Surace",
        designation:"Former CEO",
        company:"Buisness Growth Expert",
        link:"https://it.linkedin.com/in/giuseppesurace/"
    },    {
        img:require("./speakers/enrico.jpg"),
        name:"Politecnico di Milano ",
        designation:"Professor",
        company:"Politecnico di Milano ",
        link:"https://www.linkedin.com/in/enricozio/"
    },    {
        img:require("./speakers/techStars-Jens.jfif"),
        name:"Jens IngeIstedt",
        designation:"Investment Analyst & Program Manager",
        company:"Techstars",
        link:"https://www.linkedin.com/in/ingelstedt/"
    },    {
        img:require("./speakers/Catherine.jpeg"),
        name:"Catherine Carlton",
        designation:"Former Mayor ",
        company:"Menlo Park",
        link:"https://www.linkedin.com/in/catcarlton/"
    },    {
        img:require("./speakers/noman.jpg"),
        name:"Noman Azhar",
        designation:"Chief Officer",
        company:"Zindigi",
        link:"https://pk.linkedin.com/in/noman-azhar-14141230/"
    },    {
        img:require("./featured/CEO-fori.png"),
        name:"Musstanser Tinauli",
        designation:"CEO",
        company:"Fori",
        link:"https://no.linkedin.com/in/tinauli/"
    }
]
export const partners=[
    {img:require("./partners/nio.png")},
    {img:require("./partners/aws.png")},
    {img:require("./partners/Techstars.png")},
    {img:require("./logo/forilogo.png")},
    {img:require("./partners/emergence.png")},
]
export const osolopic=[
    {
        img:require("./featured/1.jpg"),
        id:1
    },
    {
        img:require("./featured/2.jpg"),
        id:2
    },
    {
        img:require("./featured/3.jpg"),
        id:3
    },
    {
        img:require("./featured/4.jpg"),
        id:4
    },
    {
        img:require("./featured/5.jpg"),
        id:5
    },
    {
        img:require("./featured/6.jpg"),
        id:6
    } 
]