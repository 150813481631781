import { BrowserRouter as Router, Routes,Route } from "react-router-dom";
import './App.css';
import Footer from "./components/footer/footer";
import Home from "./pages/home/home"
import Register from "./pages/register/register"
import Sponsor from "./pages/Sponsor/Sponsor"
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import Thankyou from "./pages/Thankyou/Thankyou";

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/register-delegate" element={<Register/>}/>
          <Route path="/register-sponsor" element={<Sponsor/>}/>
          <Route path="/thankyou" element={<Thankyou/>}/>
        </Routes>
      </Router>
      <Footer/>
    </div>
  );
}

export default App;
