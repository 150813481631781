import Header from "../../components/header/header"
import '../register/register.css'
import React ,{useState} from "react"
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom"

const Sponsor = () => {
  const navigate = useNavigate()
const [data, setdata] = useState({
  email:'',
  fullName:'',
  organizationType:'',
  organizationName:'',
  ticketTypenew:'',

})



const ValueUpdater = (e)=>{
    setdata({...data , [e.target.name]:e.target.value})
}

    const submit =async (e)=>{
      e.preventDefault()
      var reqiuredfield=0;
      const validatefield={
        email:data.email,
        fullName:data.fullName,
        organizationName:data.organizationName,
        ticketTypenew:data.ticketTypenew
      }
      Object.entries(validatefield).forEach((fieldName) => {
        if(!fieldName[1]){
          document.getElementById(`${fieldName[0]}Span`).style.color="red"
        }else{
          document.getElementById(`${fieldName[0]}Span`).style.color="black"
          reqiuredfield=reqiuredfield+1
        }
      })
      if(reqiuredfield===Object.keys(validatefield).length){
        try{
          const response = await axios.post('https://curatetomorrow.fori.co/sponsor', data)
          console.log(response)
          if(response.status===200 && response.statusText==="OK"){
            navigate("/thankyou")
          }
        }
        catch(err){
          toast.error(err.message)
        }
      }
      else{
        toast.info("please fill all required field")
      }
    }

  return (
    <div className="RigisterFormParent">
      <Header/>
      <div className='header-register'>
        <div>
          <img  src={require('../../assets/logo/fori-company-white.png')} alt="logo"/>
       </div>
       <h1>Register as a Delegate</h1>
      </div>
      
      <div className="formParent">
      <form className="registrationForm" encType="multipart/form-data">
        <div className="formInputs">
          <div>
          <label htmlFor="email">Email Address<span id="emailSpan">*</span></label>
          <input type="email" id="email" name='email' value={data.email} onChange={ValueUpdater}/>
          </div>

          <div>
          <label htmlFor="OrganizationsName">Organizations Name <span id="organizationNameSpan">*</span></label>
          <input type="text" id="organizationName" name='organizationName' value={data.organizationName} onChange={ValueUpdater}/>
          </div>

          <div>
          <label htmlFor="FullName">Full Name <span id="fullNameSpan">*</span></label>
          <input type="text" id="fullName" name='fullName' value={data.fullName} onChange={ValueUpdater}/>
          </div>
        </div>

        <div className="formOptions">
        <div className="radioDiv">
          <p>Select Sponsor Type <span id="ticketTypenewSpan">*</span></p>

          <div>
          <div>
            <input type="radio" id="Primary" name="ticketTypenew" value="Primary" onChange={ValueUpdater}/>
            <label htmlFor="Primary">Primary ($3000)</label>
          </div>

          <div>
            <input type="radio" id="SilverPartner" name="ticketTypenew" value="SilverPartner" onChange={ValueUpdater}/>
            <label htmlFor="SilverPartner">Silver Partner ($8000)</label>
          </div>

          <div>
            <input type="radio" id="GoldPartner" name="ticketTypenew" value="GoldPartner" onChange={ValueUpdater}/>
            <label htmlFor="GoldPartner">Gold Partner ($15000)</label>
          </div>

          <div>
            <input type="radio" id="PlatinumPartner" name="ticketTypenew" value="PlatinumPartner" onChange={ValueUpdater}/>
            <label htmlFor="PlatinumPartner">Platinum Partner ($30000)</label>
          </div>

          <div>
            <input type="radio" id="PoweredBy" name="ticketTypenew" value="PoweredBy" onChange={ValueUpdater}/>
            <label htmlFor="PoweredBy">Powered By ($60000)</label>
          </div>

          </div>
        </div>
        </div>

        <button type="submit" className="submitBtn" onClick={submit}>Submit</button>
      </form>
      </div>
      <ToastContainer />
    </div>
  )
}

export default Sponsor;